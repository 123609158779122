@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap');

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.custom-outline{

    outline: 3px solid rgba(0, 0, 0, 0.3);
    outline-offset: 3px;
}


.bg-button-custom{
  background: linear-gradient(183.22deg, #bd00ff, #8300d3);

}

.custom-login-auth-button{

  border: 1px solid #D8DADC;
  border-radius: 6px;
  padding: 5px 50px;
  cursor: pointer;
}

.custom-login-box{
  border: 1px solid #eee;
  border-radius: 6px;
  padding: 25px 45px;
  height: 50%;
  width: 70%;
  background: linear-gradient(183.22deg, #C13EF4 2.66%, #9209D2 97.34%);

}
.custom-login-box-2{
  border: 1px solid #eee;
  border-radius: 6px;
  padding: 20px 45px;
  height: 40%;
  width: 70%;
  margin-top: 50px;
  background: linear-gradient(180deg, #bc00ff, #6b01ac);
}

.login-form-text{
font-size: 76px;
line-height: 90px;
font-weight: 900;
font-family: Inter;
text-align: left;
color: #fff;
}

.login-form-text2 {
  font-weight:300;
  font-size: 24px;
  font-family: Inter;
  line-height: 40px;
  padding: 3px;
  margin: 20px 0px;
  color: #fff;
  text-align: left;

}
.login-list-text{
  font-size: 16px;
font-weight: 500;
font-family: Montserrat;
color: #fff;

}

@media (max-width: 720px) {

  .md\:hidden{
    display: none;
  }
  .loginformdiv{
    padding: 10px 10px;
    width:100%;
  }
}
@media only screen and (min-width: 1020px) and (max-width: 1520px) {

  .custom-login-box{
    border: 1px solid #eee;
    border-radius: 6px;
    padding: 20px 35px;
    height: 50%;
    width: 90%;
    background: linear-gradient(183.22deg, #C13EF4 2.66%, #9209D2 97.34%);
  
  }
  .login-form-text{
    color: white;
    font-family: Inter;
    font-size: 60px;
    line-height: 70px;
    font-weight: bold;
  }
  .login-form-text2 {
    font-weight: 200;
    font-size: 22px;
    line-height: 50px;
    padding: 3px;
    margin: 20px 0px;
    text-align: left;
    font-family: Montserrat;

  }
  .custom-login-box-2{
    border: 1px solid #eee;
    border-radius: 6px;
    padding: 10px 20px;
    height: 40%;
    width: 90%;
    margin-top: 50px;
    background: linear-gradient(183.22deg, #C13EF4 2.66%, #9209D2 97.34%);
  }
  .login-list-text{
    font-size: 15px;
  }
  
}