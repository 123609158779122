* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .main-container {
    display: flex;
  }

  .met_main_container_active {
    display: flex;

  }
  .met_main_container_inactive {
    display: flex;
    
  }

  main {
    padding: 10px;
  }
  @media screen and (max-width: 480px) {
    main {
      padding:2px;
    }
  }
  .title {
    font-size: 3rem;
    display: grid;
    place-items: center;
  }
  
  /* Sidebar */
  .sidebar {
    background-color: #F7EAFF;
    color: white;
    height: 100%;
    overflow: hidden;
  }

  .sidebar_inactive {
    background-color: #5A3472;
    color: white;
    height: 100%;
    overflow: hidden;
  }
  .sidebar_active {
    background-color: white;
    color: black;
    height: 100%;
    overflow: hidden;
  }

  
  
  .top_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 10px;
  }
  .metrictop_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 10px;
  }
  .logo {
    font-size: 18px;
    line-height: 0;
  }
  .metrictext{
    font-size: 20px;
    font-family:Inter;
    font-weight: 700;
    line-height:40px ;
  }
  .bars {
    width: 30px;
    color:black;
  }
  .metricbars{
    width: 60px;
    color: #8300D3;
  }
  .hide {
    display: none;
  }
  .icon{
   margin-top: 1px;
   font-size: 25px;
 
  }
 
  .icon:hover{
    color: white;
  }
  .icon:active{
    color: white!important;
  }
  
  
  .routes {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 50px 15px;
  }
  .link {
    display: flex;
    color: #4B5255;
    justify-content: left;
    align-items: center;
    gap: 15px;
    padding: 10px 10px;
    border-right: 4px solid transparent;
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }
  .link:hover {
    border-radius: 12px;
    background-color: #8300D3;
    transition: 0.2s ease-out;
    color: white;
  
}
  .active {
    border-radius: 12px;
    background-color: #8300D3;
    color: white;
  }
  .link_text {
    white-space: nowrap;
    font-size: 17px;
    font-family: Inter;
    font-weight: 500;
  }
  
  .menu {
    display: flex;
    color: white;
    padding: 5px 10px;
    border-right: 4px solid transparent;
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    justify-content: space-between;
  }
  .menu_item {
    display: flex;
    gap: 15px;
  }
  .menu_container {
    display: flex;
    flex-direction: column;
  }
  .menu_container .link {
    padding-left: 20px;
    border-bottom: #fff 0.5px solid;
  }

  .metric-text_active{
    font-size: 24px;
    font-weight: 600;
    padding: 10px;
    color:black;
  }
  .metric-text_inactive{
    writing-mode: vertical-rl;
    text-orientation: mixed; 
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    margin-top: 250px;
    color: white;
  }


  @media screen and (max-width: 768px) {
    .metric-text_inactive{
      writing-mode: vertical-rl;
      text-orientation: mixed; 
      font-size: 21px;
      font-weight: 600;
      text-align: center;
      margin-top: 250px;
    }
  }

  .metric-tab_active{
    display: flex;
    
  }
  .metric-tab_inactive{
    display: none;
  }
  .activeBtn{
    background-color: #8300D3;
    border: 1px solid #ffffff;
    font-weight: 400;
    color: white;
    font-size: 12px;
    border-radius: 8px;
    padding: 6px 18px;
    margin: 2px;
  }

  .InactiveBtn{
    background-color: #fff;
    border: 1px solid #ffffff;
    font-weight: 400;
    font-size: 12px;
    border-radius: 8px;
    padding: 6px 18px;
    margin:2px;
  }

  

  .activetabBtn{
    background-color: #8300D3;
    border: 1px solid #ffffff;
    font-weight: 400;
    white-space: nowrap;
    color: white;
    font-size: 14px;
    border-radius: 20px;
    padding: 6px 10px;
    margin: 2px;
  }

  .InactivetabBtn{
    background-color: transparent;
    font-weight: 400;
    white-space: nowrap;
    font-size: 14px;
    border-radius: 20px;
    padding: 6px 10px;
    margin:2px;
  }


