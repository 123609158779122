
.navigation-page-selector {
    flex-basis: 240px;
}
.navigation-progress-container {
    display: flex;
    flex-direction: column;
    align-items: end;
}
.navigation-buttons-container {
    display: flex;
    gap: 8px;
}
.navigation-button {
    line-height: 24px;
    font-weight: 600;
    font-family: 'Inter';
    font-size: 16px;
    padding: 12px 32px;
    border: none;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    background: linear-gradient(183.22deg, #BD00FF 2.66%, #8300D3 97.34%);

    color: #fff;
    cursor: pointer;
    outline: none;
}
.navigation-button:focus {
    color: #BD00FF;
    background-color: #fff;
    box-shadow: 0 0 0 2px #8300D3;
}
.navigation-button:hover {
    color: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    background: linear-gradient(183.22deg, #BD00FF 2.66%, #8300D3 97.34%);

}
.navigation-text-container {
    position: relative;
    width: 100%;
}
.navigation-text {
    position: absolute;
    right: 0;
    top: 8px;
    font-family: 'font-family';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.45);
}


.sd-row__question{
 display: flex;
 flex-direction: column;
}


.sd-body.sd-body--responsive .sd-body__navigation {
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
}

.sd-navigation__next-btn{
    color: #fff!important;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15)!important;
    background: linear-gradient(183.22deg, #BD00FF 2.66%, #8300D3 97.34%)!important;
    border-radius: 10px!important;
}
.sd-navigation__prev-btn{
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15)!important;
    border:1px solid black!important;
    border-radius: 10px!important;
    color: black!important;
}
.sd-navigation__preview-btn{
    color: #fff!important;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15)!important;
    background: linear-gradient(183.22deg, #BD00FF 2.66%, #8300D3 97.34%)!important;
    border-radius: 10px!important;
    font-family: "Inter"!important;
}
.sd-navigation__complete-btn{
    color: #fff!important;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15)!important;
    background: linear-gradient(183.22deg, #BD00FF 2.66%, #8300D3 97.34%)!important;
    border-radius: 10px!important;
}
.sd-action__title{
    color:#BD00FF;
}
.sd-action:not(.sd-action--pressed):hover, .sd-action:not(.sd-action--pressed):focus{
    background-color: var(--sjs-primary-backcolor-light, var(--primary-light, rgba(206, 71, 247, 0.1)));
}

.sd-boolean {
    box-shadow: var(--sjs-shadow-inner, inset 0px 0px 5px 1px rgba(41, 41, 41, 0.2));
}


.sd-boolean__thumb{
    background: linear-gradient(183.22deg, #BD00FF 2.66%, #8300D3 97.34%)!important;
}
.sd-boolean__thumb-text{
color: white!important;
}
.sd-boolean.sd-boolean--allowhover:focus-within {
    box-shadow: 0 0 0 2px #8300D3!important;
}
.sd-title{
    color: black!important;
    font-family: Inter!important;
}
.sd-header__text h3{
font-size: 22px!important;
}
.sd-page__title{
    font-family: Inter!important;
    font-size: 16px!important;
}
.sd-title.sd-element__title{
    font-family: Inter!important;
    font-size: 20px!important;
    font-weight: 600!important;
    line-height: 24px!important;
    text-align: left!important;
    color: black!important;;


}
.sd-element__title span{
    font-size: 14px;
    font-weight: 700;
}
.sd-page .sd-page__description{
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    text-align: left;
    color: black!important;
    
}
.sd-root-modern .sd-container-modern__title .sd-description{
    font-family: Inter!important;
    font-size: 14px!important;
    font-weight: 400!important;
    text-align: left!important;
    color: black!important;

}

.sd-element__header .sd-description{
font-family: Inter!important;
font-size: 14px!important;
font-weight: 300!important;
line-height: 25px!important;
padding: 1px;
text-align: left!important;
color: #888686!important;
margin-right: 15px;

}



.sv-components-row>.sv-components-column--expandable {
    width: 1px;
}
.sd-body.sd-body--responsive {

    padding: 1px 20px!important;

}

.sd-progress {
    height: 8px;
    border-radius: 100px!important;
    background-color: #706e6e!important;
    margin: 30px 120px!important;

}
.sd-action-bar {
    justify-content: center;
}
.sd-progress__bar {
    border-radius: 100px!important;
    background: linear-gradient(183.22deg, #BD00FF 2.66%, #8300D3 97.34%)!important;
}
.sd-title.sd-container-modern__title{
    box-shadow: none!important;
    padding: 0px 20px 0px 20px;
}
.sd-selectbase{
    width: 150px!important;
}

.sd-input.sd-dropdown:focus-within{
    box-shadow: 0 0 0 2px var(--sjs-primary-backcolor, var(--primary, #BD00FF));
}

.sv-list__item.sv-list__item--selected .sv-list__item-body, .sv-list__item.sv-list__item--selected:hover .sv-list__item-body, .sv-list__item.sv-list__item--selected.sv-list__item--focused .sv-list__item-body, .sv-multi-select-list .sv-list__item.sv-list__item--selected.sv-list__item--focused .sv-list__item-body, li:focus .sv-list__item.sv-list__item--selected .sv-list__item-body{
    background: linear-gradient(183.22deg, #BD00FF 2.66%, #8300D3 97.34%)!important; 
}

.sd-item--checked .sd-item__decorator {
    background: linear-gradient(183.22deg, #BD00FF 2.66%, #8300D3 97.34%)!important; 
}
.sd-item__control:focus+.sd-item__decorator {
    box-shadow: 0 0 0 2px var(--sjs-primary-backcolor, var(--primary, #BD00FF));
    background: var(--sjs-questionpanel-backcolor, var(--sjs-question-background, var(--sjs-general-backcolor, var(--background, #fff))));
}
.sd-radio--checked .sd-radio__decorator:after {
    content: " ";
    background-color: white!important;
}

.sd-input[type=range]:focus-within {
    box-shadow: 0 0 0 2px var(--sjs-primary-backcolor, var(--primary, #BD00FF));  
}
.sd-input[type=color]:focus-within {
    box-shadow: 0 0 0 2px var(--sjs-primary-backcolor, var(--primary, #BD00FF));   
}


.sd-body.sd-body--static {
    max-width: calc(100*(var(--sjs-base-unit, var(--base-unit, 8px))));

    padding-top: 5px!important;
    padding-bottom: 5px!important;
}

.sd-imagepicker__image {

    height: 50px;
}

#sq_100,#sq_101,#sq_102,#sq_103,#sq_104,#sq_105,#sq_106,#sq_107,#sq_108,#sq_109,#sq_110,#sq_115,#sq_117,#sq_118,#sq_119,#sq_121,#sq_122,#sq_123,#sq_125,#sq_126,#sq_127,#sq_128,#sq_129,#sq_130{
    flex-direction: row;
}



.sd-selectbase{
    width: 100%!important;
}

.sd-element__title.sd-element__title--disabled{
    opacity: 1;
}

.sd-element__title--disabled .sd-question__title--answer{
    font-size: 10px!important;
}

.sd-input.sd-text.sd-input--disabled{
    opacity: 1;
}
.sd-item .sd-checkbox .sd-selectbase__item .sv-q-col-3 .sd-item--disabled .sd-checkbox--disabled{
    opacity: 1;
}
.sd-boolean{
  padding: 0px!important;
}

.sd-boolean--disabled .sd-boolean__thumb, .sd-boolean--disabled .sd-boolean__label {
    opacity: 1;
    font-size: 14px;
}

.sd-question--disabled > .sd-element__header--location-top> .sd-question__description{
    display: none!important;

}

.sd-element--with-frame > .sd-panel__content{ 
    padding-top: 10px;
}

.sd-element--complex.sd-element--with-frame>.sd-element__header > .sd-panel__description{
    display: none!important;
}
.sd-element--complex.sd-element--with-frame >.sd-element__header > .sd-element__title span{
    font-size: 15px!important;
}
.sd-question--disabled > .sd-element__header span{
    font-size: 14px!important;
}

.sd-root-modern * {
    scrollbar-width: thin;
    scrollbar-color: var(--sjs-border-default, var(--border, #d6d6d6)) rgba(0,0,0,0);
}
.sd-panel__content {
    padding-top: 10px;
}


.sd-element--nested .sd-element .sd-question .sd-row__question .sd-question--boolean .sd-question--title-top .sd-question--answered .sd-question--disabled{
    display: flex!important;
    background-color: red;
}
.sd-item.sd-checkbox .sd-selectbase__item.sv-q-col-1.sd-item--disabled.sd-checkbox--disabled{
    display: none!important;
}




/* responsive part */
@media only screen and (max-width: 600px) {
    .sd-progress {
        height: 8px;
        border-radius: 100px!important;
        background-color: #706e6e!important;
        margin: 30px 10px!important;
    
    }
}
@media only screen and (max-width: 600px) {
    .sd-title{
        font-size: 20px!important;
        text-align: center!important;
    }
}

@media only screen and (max-width: 600px) {
    .sd-row__question{   
     display: flex;
     flex-direction: column;   
}
}

@media only screen and (max-width: 600px) {
    #sq_100,#sq_101,#sq_102,#sq_103,#sq_104,#sq_105,#sq_106,#sq_107,#sq_108,#sq_109,#sq_110,#sq_115,#sq_117,#sq_118,#sq_119,#sq_121,#sq_122,#sq_123,#sq_125,#sq_126,#sq_127,#sq_128,#sq_129,#sq_130{
        flex-direction: column;
    }
}
@media only screen and (max-width: 600px) {
  
    .sd-page .sd-page__description{

        font-size: 14px;
        
    }
}



@media only screen and (max-width: 600px) {
  
    .sd-root-modern--mobile .sd-body__navigation .sv-action:not(.sv-action--hidden), .sd-root-modern--mobile .sd-body__navigation .sd-btn {
        padding: 5px!important;
    }
}

@media only screen and (min-width: 1020px) {
  
    .sd-root-modern--mobile .sd-body__navigation .sv-action:not(.sv-action--hidden), .sd-root-modern--mobile .sd-body__navigation .sd-btn {
        padding: 5px!important;
    }
}


@media only screen and (min-width: 920px) {
  
    .sd-root-modern__wrapper {
        width: 900px!important;
    }
}



@media only screen and (max-width: 600px) {
  
    .sd-root-modern.sd-root-modern--mobile .sd-header__text{

        margin-top: -25px;

        
    }
}